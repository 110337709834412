import * as React from "react"
import LayoutBlog from "../components/LayoutBlog"
import BlogPage2 from "../components/BlogPage/BlogPage2"
import CommentBox from '../components/BlogPage/CommentBox'

function blog({ data }) {
  return (
    <LayoutBlog>
      <BlogPage2/>
      <CommentBox />
    </LayoutBlog>
  )
}

export default blog




